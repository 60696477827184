@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body,
#root,
.app,
.content {
  -webkit-transition: all 0.5s;
  transition: direction 0.3s ease !important;
  height: 100%;
  width: 100%;
  font-family: "Segoe UI", Source Sans Pro, sans-serif;
  --sidebar-color: #344955;
  --primary-color: #f9aa33;
  --primary-color-icon: #344955;
  --primary-color-hover: #db952d;
  --secondary-color: #57727f;
  --secondary-color-hover: #3f555f;
  --menuItem-color: #ffffff;
  --red-color: #F90716;
  --success-color: #198754;
  --info-color: #0DCAF0;
  --tile-hover-color: whitesmoke;
  --ag-hover-color: #f5fcfb;
  scroll-behavior: smooth;
}

.mobile-bg-color {
  background-color: #EDEDED;
}

.p-accordion-header-link {
  background-color: white !important;
}

.p-accordion-header-text {
  margin-left: 7rem;
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 0 !important;
}

.p-accordion .p-accordion-toggle-icon {
  margin-left: 1rem !important;
  font-size: 14px !important;
}

.mobile-dropdown .p-dropdown-label {
  font-size: 16px !important;
  color: gray !important;
  padding: 0.35rem !important;
}

.mobile-label {
  font-size: 0.65rem;
}

.mobile-invoice-input:out-of-range {
  border: 1px solid red !important;
  box-shadow: inherit;
}

/* 
.p-sidebar-close {
  color: whitesmoke !important;
} */

*::selection {
  background-color: var(--primary-color);
  color: white;
}

.app {
  display: flex;
  position: relative;
}

/* Main center Content Components styles */
/* .content {
    padding-left: 10px;
} */
.hover-none:hover {
  background-color: none !important;
}

.box-container {
  margin: 10px;
  margin-top: 0.7rem;
}

canvas {
  height: 600 !important;
  width: 1000 !important;
  overflow: hidden !important;
}

.box-container {
  opacity: 0;
  /* transform: translateX(-100%); */
  transition: opacity 0.3s ease;
  /* transition: opacity 0.3s ease, transform 0.3s ease; */
}

.box-container.fade-in {
  opacity: 1;
  /* transform: translateX(0); */
}

.admin-tapbar-container {
  margin-bottom: unset !important;
}

.MuiTab-root {
  padding-bottom: 10px !important;
}

.admin-box-container {
  margin: 1.2rem 1rem 2rem 1rem;
}

.heading-h3 {
  font-weight: 500px;
  font-size: 18px;
}

.address-wrapper {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.card-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.date-dropdown {
  padding: 0% !important;
  font-size: 8px !important;
}

.form-control:disabled {
  background-color: rgb(243, 243, 243);
  opacity: 1;
}

.p-dropdown:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* Primereact Dropdown Items */
.p-menu .p-menuitem-link {
  padding-left: 0 !important;
}

.p-menu .p-menuitem-link:hover {
  background-color: #f3eaeaad !important;
}

/* Primereact Dialog Zindex */
.p-dialog-mask {
  z-index: 100 !important;
}

.p-menu .p-menuitem-link:hover {
  color: white !important;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #b1afaf;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee9e9;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f6d6d;
}

.sidebar-color {
  color: var(--sidebar-color);
}

.product-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.p-dataview-content li {
  border-bottom: none !important;
}

.product-badge.secondary-color {
  color: #ffffff !important;
  background: var(--sidebar-color);
}

.product-badge.primary-color {
  color: #ffffff !important;
  background: var(--primary-color);
}

.product-badge-primary-outlined {
  background: #ffffff !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  cursor: pointer;
}

.product-badge-secondary-outlined {
  background: #ffffff !important;
  color: var(--sidebar-color) !important;
  border: 1px solid var(--sidebar-color) !important;
}

.product-badge-red-outlined {
  background: #ffffff !important;
  color: var(--red-color) !important;
  border: 1px solid var(--sidebar-color) !important;
  cursor: pointer;
}

.product-badge.status-outofstock {
  background: #ffcdd2;
  color: #c63737;
}

.p-inputtext:focus {
  box-shadow: none !important;
}

.css-1d574l3-MuiTabs-indicator {
  background-color: var(--sidebar-color) !important;
}

.p-button {
  font-size: 0.775rem !important;
  padding: 0.3rem 0.5rem !important;
}

.xlarge-button {
  font-size: 20px;
  padding: 10px 20px;
}




.primary-color {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--secondary-color) !important;
}

.primary-color-icon {
  color: var(--primary-color-icon) !important;
}

/* Primary Button Styles */

/* ************************* */
.primary-button {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: 1px solid var(--primary-color) !important;
  font-weight: 600 !important;
}

.primary-button:hover {
  background-color: var(--primary-color-hover) !important;
  color: white !important;
}

.primary-button-outlined {
  color: var(--primary-color) !important;
  background-color: white !important;
  border: 1px solid var(--primary-color) !important;
}

.primary-button-outlined:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}


.primary-input-text:focus {
  border: 2px solid var(--primary-color) !important;
}

.primary-badge {
  background-color: var(--primary-color) !important;
}

.tertiary-button {
  background-color: var(--tertiary-color) !important;
  color: white !important;
  border: 1px solid var(--tertiary-color) !important;
}

/* Secondary Button Styles */
.secondary-button {
  background-color: var(--secondary-color) !important;
  color: white !important;
  border: 1px solid var(--secondary-color) !important;
}

.secondary-button:hover {
  background-color: var(--secondary-color-hover) !important;
  color: white !important;
}

.secondary-button-outlined {
  color: var(--secondary-color) !important;
  background-color: white !important;
  border: 1px solid var(--secondary-color) !important;
}

.secondary-button-outlined:hover {
  background-color: var(--secondary-color) !important;
  color: white !important;
}

.secondary-button:focus {
  border: 1px solid var(--secondary-color) !important;
  box-shadow: none !important;
}

.secondary-badge {
  background-color: var(--secondary-color) !important;
}

@media screen and (max-width:768px) {
  .hide-in-mobileview {
    display: none !important;
  }

  .custom-toastr {
    /* background-color: #f1f3f596 !important; */
    /* color: white !important; */
    /* border: 2px solid #344955be !important; */
    /* width: 25vw; */
    color: var(--sidebar-color) !important;
    font-size: 15px !important;
    backdrop-filter: blur(10px) !important;
  }
}

/* General Details */
/* Mobile M view  */
@media screen and (min-width: 320px) and (max-width: 376px) {
  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248,
        246,
        246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
    font-size: 9px;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
  }

  .containers>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  .containers label {
    font-weight: 500;
  }

  .Note {
    display: flex;
    margin-bottom: 5px;
    align-items: flex-start;
  }

  .Note label {
    font-weight: 500;
    margin-right: 3.5%;
  }

  .BadgeView {
    display: flex;
    flex-direction: column;
  }

  .custom-toastr {
    /* background-color: #f1f3f596 !important; */
    /* color: white !important; */
    /* border: 2px solid #344955be !important; */
    /* width: 25vw; */
    color: var(--sidebar-color) !important;
    font-size: 15px !important;
    backdrop-filter: blur(10px) !important;
  }
}

/* Mobile L view  */
@media screen and (min-width: 376px) and (max-width: 426px) {
  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248,
        246,
        246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
    font-size: 12px;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
  }

  .containers>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  .containers label {
    font-weight: 500;
  }

  .Note {
    display: flex;
    margin-bottom: 5px;
    align-items: flex-start;
  }

  .Note label {
    font-weight: 500;
    margin-right: 3.5%;
  }

  .BadgeView {
    display: flex;
    flex-direction: column;
  }
}

/* Tablet View  */
@media screen and (min-width: 426px) and (max-width: 768px) {
  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248,
        246,
        246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
    font-size: 11px;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    /* gap: 10px; */
  }

  .containers>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  .containers label {
    font-weight: 500;
  }

  .Note {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .Note label {
    font-weight: 500;
    margin-right: 3.5%;
  }

  .BadgeView {
    display: flex;
    justify-content: flex-end;
  }
}

/* fullScreen css  */
@media screen and (min-width: 769px) {
  .custom-toastr {
    /* background-color: #f1f3f596 !important; */
    /* color: white !important; */
    /* border: 2px solid #344955be !important; */
    width: 25vw;
    color: var(--sidebar-color) !important;
    font-size: 15px !important;
    backdrop-filter: blur(10px) !important;
  }

  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248,
        246,
        246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    /* gap: 10px; */
  }

  .containers div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .containers label {
    font-weight: 500;
  }

  .Note {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .Note label {
    font-weight: 500;
    margin-right: 3.5%;
  }

  .BadgeView {
    display: flex;
    justify-content: flex-end;
  }
}

.tooltip-fontsize {
  font-size: 14px !important;
}

/* Datagrid Switch for Admin panel */
/* 
.switch {
  --button-width: 3.5em;
  --button-height: 2em;
  --toggle-diameter: 1.5em;
  --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
  --toggle-shadow-offset: 10px;
  --toggle-wider: 3em;
  --color-grey: #cccccc;
  --color-green: #4296f4;
}

.slider {
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: var(--color-grey);
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  transition: 0.3s all ease-in-out;
}

.slider:hover {
  cursor: pointer;
}

.slider::after {
  content: "";
  display: inline-block;
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  background-color: #fff;
  border-radius: calc(var(--toggle-diameter) / 2);
  position: absolute;
  top: var(--button-toggle-offset);
  transform: translateX(var(--button-toggle-offset));
  box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked+.slider {
  background-color: var(--primary-color);
}

.switch input[type="checkbox"]:checked+.slider::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
  box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
  display: none;
}

.switch input[type="checkbox"]:active+.slider::after {
  width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active+.slider::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
} */

.switch {
  --button-width: 3.5em;
  --button-height: 2em;
  --toggle-diameter: 1.5em;
  --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
  --toggle-shadow-offset: 10px;
  --toggle-wider: 3em;
  --color-grey: #cccccc;
  --color-green: #4296f4;
}

.slider {
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: var(--color-grey);
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  transition: 0.3s all ease-in-out;
}

.slider:hover {
  cursor: pointer;
}

.slider::after {
  content: "";
  display: inline-block;
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  background-color: #fff;
  border-radius: calc(var(--toggle-diameter) / 2);
  position: absolute;
  top: var(--button-toggle-offset);
  transform: translateX(var(--button-toggle-offset));
  box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked+.slider {
  background-color: var(--primary-color);
}

.switch input[type="checkbox"]:checked+.slider::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
  box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
  display: none;
}

.switch input[type="checkbox"]:active+.slider::after {
  width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active+.slider::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}

/* Styles for disabled state */
.switch input[type="checkbox"]:disabled+.slider {
  background-color: #f9aa33a0;
  cursor: not-allowed;
  pointer-events: none;
}

.switch input[type="checkbox"]:disabled+.slider::after {
  background-color: white;
  box-shadow: none;
}


/*  */

.p-autocomplete-input {
  height: 40px;
  border-radius: unset !important;
}

.p-autocomplete-input:focus {
  outline: unset !important;
  box-shadow: unset !important;
  border-color: gainsboro !important;
}

.p-autocomplete-dropdown {
  background-color: var(--sidebar-color) !important;
  border: 2px solid var(--sidebar-color) !important;
  border-radius: unset !important;
}

.p-autocomplete-item:hover {
  color: white !important;
  background-color: var(--sidebar-color) !important;
}

/* MUI Datepicker */
/* .css-11xcpd1-MuiInputBase-root-MuiOutlinedInput-root {
  height: 38px !important;
} */

.css-1hjkutt-MuiButtonBase-root-MuiPickersDay-root {
  color: var(--sidebar-color) !important;
  background-color: white !important;
}

.css-qkra9h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  color: white !important;
  background-color: var(--sidebar-color) !important;
}

.css-qkra9h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: white !important;
  background-color: var(--sidebar-color) !important;
}

.css-qkra9h-MuiButtonBase-root-MuiPickersDay-root:hover {
  color: white !important;
  background-color: var(--sidebar-color) !important;
}

.css-1mqv45l-MuiPickersYear-yearButton.Mui-selected {
  color: white !important;
  background-color: var(--sidebar-color) !important;
}

.css-1mqv45l-MuiPickersYear-yearButton:hover {
  color: white !important;
  background-color: var(--sidebar-color) !important;
}

.css-1hjkutt-MuiButtonBase-root-MuiPickersDay-root {
  color: white !important;
  background-color: var(--sidebar-color) !important;
}

.network-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
    /* Optional: Slide in from the top */
  }

  to {
    opacity: 1;
    transform: translateY(0);
    /* Optional: Slide in from the top */
  }
}

.network-error svg {
  width: 48px;
  /* Adjust the size of the SVG icon */
  height: 48px;
  margin-bottom: 10px;
  /* Add some spacing between the icon and the text */
}

.network-error p {
  text-align: center;
  margin: 0;
}