@media screen and (min-width:769px) {
    .InvoiceTopLine {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: -15px;
    }

    .CreditNoteTopLine {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: -15px;
    }

    .YearLabelSpan {
        font-weight: 500;
        font-size: 13px !important;
        margin-left: -13px
    }

    .YearDropDown {
        width: 125% !important;
        height: 35px !important;
    }

    .SOADropdown {
        width: 12% !important;
        height: 30px !important;
    }

    .GeneralSearchBar {
        width: 50%;
        height: 2rem;
    }

    .SearchInput {
        height: 35px;
        width: 100%;
        text-align: center;

    }

    .InvoiceSearchDiv {
        width: 60%;
        display: flex;
        justify-content: center;

    }

    .InvoiceSearchDiv>span {
        width: 80%;
    }

    .InvoiceSearchInput {
        height: 35px;
        width: 100%;
        text-align: center;
    }

    .CreditNoteSearchDiv {
        width: 60%;
        display: flex;
        justify-content: center;

    }

    .CreditNoteSearchDiv>span {
        width: 80%;
    }

    .CreditNoteSearchInput {
        height: 35px;
        width: 100%;
        text-align: center;
    }

    .ag-cell {
        color: var(--sidebar-color);
    }

    .ag-cell:focus {
        border: 2px solid var(--primary-color) !important;
    }

    .highlight {
        /* background-color: yellow !important; */
        /* Customize highlight color */
        font-weight: bold !important;
        /* Customize font-weight */
    }

    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 10px !important;
        padding-right: 10px !important;
        /* padding-right: var(--ag-cell-horizontal-padding); */
    }

    .custom-ag-header {
        font-weight: 600 !important;
        text-transform: uppercase;
        font-size: 11px !important;
        border-bottom: 1px solid var(--sidebar-color);
        background-color: var(--sidebar-color) !important;
        color: white !important;
    }

    .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
        color: var(--primary-color) !important;
    }

    .ag-theme-alpine .ag-row {
        background-color: rgb(245, 240, 240) !important;
        border-top: 8px solid white !important;
        border-bottom: 1px solid white !important;
        border-top-left-radius: 3px !important;
    }


    .rounded-row {
        font-size: 13px !important;
        font-weight: 600 !important;
    }

    .custom-selected-row {
        background-color: unset !important;
        /* Reset the background color */
        color: unset !important;
        /* Reset the text color */
    }

    .custom-selected-row :hover {
        background-color: #F5F0F0 !important;
    }

    .p-dropdown .p-dropdown-label.p-placeholder,
    .p-dropdown-label {
        margin-top: -0.3rem !important;
    }


    .ag-theme-alpine {
        --ag-selected-row-background-color: unset !important;
        --ag-range-selection-border-style: none !important;
    }

    /* .ag-theme-alpine .ag-row-hover {
        background-color: rgba(196, 165, 165, 0.858) !important;
    } */

    .ag-theme-alpine .ag-paging-panel {
        background-color: #ffffffc4 !important;
        border: none;
    }

    .custom-ag-header:hover {
        background-color: var(--sidebar-color) !important;
    }

    .ag-icon-menu {
        color: white !important;
    }

    .ag-icon-asc,
    .ag-icon-desc {
        color: white !important;
    }

    .ag-header-container {
        background-color: var(--sidebar-color) !important;
    }

    /* AG grid tooltip */
    .ag-tooltip {
        background-color: var(--sidebar-color) !important;
        color: white !important;
        border-radius: 5px !important;
    }
}

@media screen and (min-width:1440px) {
    .SOADropdown {
        width: 8% !important;
        height: 35px !important;
    }
}

@media screen and (min-width:769px) and (max-width:1024px) {
    .SOADropdown {
        width: 12% !important;
        height: 35px !important;
    }
}

@media screen and (min-width:673px) and (max-width:768px) {
    .InvoiceTopLine {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-end;
        margin-top: -12px;
    }

    .CreditNoteTopLine {
        display: flex;
        gap: 20px;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: -12px;
    }

    .YearLabelSpan {
        font-weight: 500;
        font-size: 13px !important;
        margin-left: -13px
    }

    .YearDropDown {
        width: 125% !important;
        height: 35px !important;
    }


    .GeneralSearchBar {
        width: 50%;
    }

    .SearchInput {
        height: 35px;
        width: 100%;
        text-align: center;
    }

    .InvoiceSearchDiv {
        width: 60%;
        display: flex;
        justify-content: center;

    }

    .InvoiceSearchDiv>span {
        width: 80%;
    }

    .InvoiceSearchInput {
        height: 35px;
        width: 100%;
        text-align: center;
    }

    .CreditNoteSearchDiv {
        width: 60%;
        display: flex;
        justify-content: center;

    }

    .CreditNoteSearchDiv>span {
        width: 80%;
    }

    .CreditNoteSearchInput {
        height: 35px;
        width: 100%;
        text-align: center;
    }

    .ag-cell:focus {
        border: 2px solid var(--primary-color) !important;
    }

    .highlight {
        font-weight: bold !important;
    }

    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 2rem !important;
        /* padding-right: var(--ag-cell-horizontal-padding); */
    }

    .ag-header {
        height: 2rem !important;
        min-height: 2rem !important;
    }

    .ag-row .ag-cell {
        font-size: 9px !important;

    }

    .ag-cell-value {
        margin-top: -5px;
    }

    .custom-ag-header {
        font-weight: 600 !important;
        text-transform: uppercase;
        font-size: 8px !important;
        border-bottom: 1px solid var(--sidebar-color);
        background-color: var(--sidebar-color) !important;
        color: white !important;
    }


    .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
        color: var(--primary-color) !important;
    }

    .ag-theme-alpine .ag-row {
        background-color: rgb(245, 240, 240) !important;
        border-top: 6px solid white !important;
        border-bottom: 1px solid white !important;
        border-top-left-radius: 3px !important;
    }


    .rounded-row {
        font-size: 13px !important;
        font-weight: 600 !important;
    }

    .custom-selected-row {
        background-color: unset !important;
        color: unset !important;
    }

    .custom-selected-row :hover {
        background-color: #F5F0F0 !important;
    }

    .p-dropdown .p-dropdown-label.p-placeholder,
    .p-dropdown-label {
        margin-top: -0.3rem !important;
    }

    /* Custom styles for the Dropdown component */
    .custom-dropdown-container .custom-dropdown .p-dropdown-trigger.p-dropdown-focus {
        box-shadow: 0 0 4px #ff0000 !important;
    }

    .ag-theme-alpine {
        --ag-selected-row-background-color: unset !important;
        --ag-range-selection-border-style: none !important;
    }

    /* .ag-theme-alpine .ag-row-hover {
        background-color: rgba(196, 165, 165, 0.858) !important;
    } */

    .ag-theme-alpine .ag-paging-panel {
        background-color: #ffffffc4 !important;
        border: none;
    }

    .custom-ag-header:hover {
        background-color: var(--sidebar-color) !important;
    }

    .ag-icon-menu {
        color: white !important;
    }

    .ag-icon-asc,
    .ag-icon-desc {
        color: white !important;
    }

    .ag-header-container {
        background-color: var(--sidebar-color) !important;
    }

    /* AG grid tooltip */
    .ag-tooltip {
        background-color: var(--sidebar-color) !important;
        color: white !important;
        border-radius: 5px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 9px !important;
        height: 20px !important;
        line-height: 20px !important;
    }

    .ag-paging-page-summary-panel {
        font-size: 9px !important;
    }

    .ag-paging-panel {
        height: 2rem !important;
        margin-bottom: -10px !important;
    }


}

@media screen and (min-width:321px) and (max-width:672px) {
    .SOADropdown {
        width: 22% !important;
        height: 1.4rem !important;
    }

    .InvoiceTopLine {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 8px;
        row-gap: 10px;
        width: 100%;
        align-items: center;
    }

    .InvoiceTopLine>div:nth-child(1) {
        margin-top: -18px;
    }

    .InvoiceTopLine>div:nth-child(5) {
        margin-left: 5px;
        width: 0%;
    }

    .CreditNoteTopLine {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: -12px;
    }

    .CreditNoteTopLine>div:nth-child(3) {
        margin-left: -10px;
    }



    /* dropdown style  */
    .YearLabelSpan {
        font-weight: 450;
        font-size: 9px !important;

    }

    .YearDropDown {
        height: 25px !important;
        width: 100% !important;
    }

    .p-dropdown-label {
        font-size: 9px !important;
    }

    .p-dropdown-trigger {
        width: 15px !important;
        margin-right: 10px !important;
    }

    .p-dropdown-trigger svg {
        width: 10px;
        height: 10px;
    }

    .p-dropdown-items {
        margin-left: -10px;
    }

    .p-dropdown-items-wrapper {
        font-size: 10px !important;
    }

    .p-dropdown-panel {
        width: 10% !important;
    }

    .p-dropdown-item {
        height: 1.5rem !important;
    }

    .p-dropdown-items {
        padding: none !important;
    }

    /* SearchBar  */
    .GeneralSearchBar {
        width: 40%;
    }

    .SearchInput {
        height: 25px;
        width: 100%;
        font-size: 10px !important;
        text-align: center;
    }

    .pi.pi-search {
        padding: 1px;
        font-size: smaller;
    }

    .InvoiceSearchDiv {
        width: 80%;
    }

    .InvoiceSearchInput {
        height: 25px;
        text-align: center;
        font-size: 10px !important;
        width: 120px;
    }

    .CreditNoteSearchDiv {
        width: 80%;
        margin-left: -10px;
    }

    .CreditNoteSearchInput {
        height: 25px;
        text-align: center;
        font-size: 10px !important;
        width: 170px;
    }

    .PayButton {
        height: 22px !important;
    }

    .p-button-icon {
        font-size: smaller !important;
    }

    .customIcon {
        font-size: 14px !important;
    }





    .ag-cell:focus {
        border: 2px solid var(--primary-color) !important;
    }

    .highlight {
        font-weight: bold !important;
    }

    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 2rem !important;
        /* padding-right: var(--ag-cell-horizontal-padding); */
    }

    .ag-header {
        height: 2rem !important;
        min-height: 2rem !important;
    }

    .ag-row .ag-cell {
        font-size: 9px !important;

    }

    .ag-cell-value {
        margin-top: -5px;
    }

    .custom-ag-header {
        font-weight: 600 !important;
        text-transform: uppercase;
        font-size: 8px !important;
        border-bottom: 1px solid var(--sidebar-color);
        background-color: var(--sidebar-color) !important;
        color: white !important;
    }


    .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
        color: var(--primary-color) !important;
    }

    .ag-theme-alpine .ag-row {
        background-color: rgb(245, 240, 240) !important;
        border-top: 6px solid white !important;
        border-bottom: 1px solid white !important;
        border-top-left-radius: 3px !important;
    }


    .rounded-row {
        font-size: 13px !important;
        font-weight: 600 !important;
    }

    .custom-selected-row {
        background-color: unset !important;
        color: unset !important;
    }

    .custom-selected-row :hover {
        background-color: #F5F0F0 !important;
    }

    .p-dropdown .p-dropdown-label.p-placeholder,
    .p-dropdown-label {
        margin-top: -0.3rem !important;
    }

    /* Custom styles for the Dropdown component */
    .custom-dropdown-container .custom-dropdown .p-dropdown-trigger.p-dropdown-focus {
        box-shadow: 0 0 4px #ff0000 !important;
    }

    .ag-theme-alpine {
        --ag-selected-row-background-color: unset !important;
        --ag-range-selection-border-style: none !important;
    }

    /* .ag-theme-alpine .ag-row-hover {
        background-color: rgba(196, 165, 165, 0.858) !important;
    } */

    .ag-theme-alpine .ag-paging-panel {
        background-color: #ffffffc4 !important;
        border: none;
    }

    .custom-ag-header:hover {
        background-color: var(--sidebar-color) !important;
    }

    .ag-icon-menu {
        color: white !important;
    }

    .ag-icon-asc,
    .ag-icon-desc {
        color: white !important;
    }

    .ag-header-container {
        background-color: var(--sidebar-color) !important;
    }

    /* AG grid tooltip */
    .ag-tooltip {
        background-color: var(--sidebar-color) !important;
        color: white !important;
        border-radius: 5px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 9px !important;
        height: 20px !important;
        line-height: 20px !important;
    }

    .ag-paging-page-summary-panel {
        font-size: 9px !important;
    }

    .ag-paging-panel {
        height: 2rem !important;
        margin-bottom: -10px !important;
    }


}

@media screen and (max-width:320px) {

    .SOADropdown {
        width: 20% !important;
        height: 1.3rem !important;
    }

    .InvoiceTopLine {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 10px;
        row-gap: 5px;
        width: 100%;
        align-items: center;
    }

    .InvoiceTopLine>div:nth-child(1) {
        margin-top: -18px;
    }

    .InvoiceTopLine>div:nth-child(3) {
        margin-left: -5px;
    }

    .InvoiceTopLine>div:nth-child(4) {
        margin-left: -5px;
    }

    .InvoiceTopLine>div:nth-child(5) {
        margin-left: -5px;
    }

    .CreditNoteTopLine {
        display: flex;
        gap: 20px;
        width: 100%;
        align-items: flex-end;
        margin-top: -12px;

    }

    .CreditNoteTopLine>div:nth-child(3) {
        margin-left: -13px;
    }



    /* dropdown style  */
    .YearLabelSpan {
        font-weight: 450;
        font-size: 8px !important;

    }

    .YearDropDown {
        height: 23px !important;
        width: 100% !important;
    }

    .p-dropdown-label {
        font-size: 9px !important;
    }

    .p-dropdown-trigger {
        width: 8px !important;
        margin-right: 2px !important;
    }

    .p-dropdown-trigger svg {
        width: 10px;
        height: 10px;
    }

    .p-dropdown-items {
        margin-left: -10px;
    }

    .p-dropdown-items-wrapper {
        font-size: 10px !important;
    }

    .p-dropdown-panel {
        width: 10% !important;
    }

    .p-dropdown-item {
        height: 1.5rem !important;
    }

    .p-dropdown-items {
        padding: none !important;
    }

    /* SearchBar  */
    .GeneralSearchBar {
        width: 40%;
    }

    .SearchInput {
        height: 25px;
        width: 100%;
        font-size: 10px !important;
        text-align: center;
    }

    .InvoiceSearchDiv {
        width: 80%;
    }

    .InvoiceSearchInput {
        height: 25px;
        text-align: center;
        font-size: 10px !important;
        width: 100px;
    }

    .CreditNoteSearchDiv {
        width: 80%;
        margin-left: -10px;
    }

    .CreditNoteSearchInput {
        height: 25px;
        text-align: center;
        font-size: 10px !important;
        width: 140px;
    }

    .pi.pi-search {
        padding: 1px;
        font-size: smaller;
    }

    .PayButton {
        height: 22px !important;
    }

    .p-button-label {
        font-size: smaller;
    }

    .p-button-icon {
        font-size: smaller !important;
    }

    .customIcon {
        font-size: 13px !important;
    }


    .ag-cell:focus {
        border: 2px solid var(--primary-color) !important;
    }

    .highlight {
        font-weight: bold !important;
    }

    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 2rem !important;
        /* padding-right: var(--ag-cell-horizontal-padding); */
    }

    .ag-header {
        height: 2rem !important;
        min-height: 2rem !important;
    }

    .ag-row .ag-cell {
        font-size: 9px !important;

    }

    .ag-cell-value {
        margin-top: -5px;
    }

    .custom-ag-header {
        font-weight: 600 !important;
        text-transform: uppercase;
        font-size: 8px !important;
        border-bottom: 1px solid var(--sidebar-color);
        background-color: var(--sidebar-color) !important;
        color: white !important;
    }


    .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
        color: var(--primary-color) !important;
    }

    .ag-theme-alpine .ag-row {
        background-color: rgb(245, 240, 240) !important;
        border-top: 6px solid white !important;
        border-bottom: 1px solid white !important;
        border-top-left-radius: 3px !important;
    }


    .rounded-row {
        font-size: 13px !important;
        font-weight: 600 !important;
    }

    .custom-selected-row {
        background-color: unset !important;
        color: unset !important;
    }

    .custom-selected-row :hover {
        background-color: #F5F0F0 !important;
    }

    .p-dropdown .p-dropdown-label.p-placeholder,
    .p-dropdown-label {
        margin-top: -0.3rem !important;
    }

    /* Custom styles for the Dropdown component */
    .custom-dropdown-container .custom-dropdown .p-dropdown-trigger.p-dropdown-focus {
        box-shadow: 0 0 4px #ff0000 !important;
    }

    .ag-theme-alpine {
        --ag-selected-row-background-color: unset !important;
        --ag-range-selection-border-style: none !important;
    }

    /* .ag-theme-alpine .ag-row-hover {
        background-color: rgba(196, 165, 165, 0.858) !important;
    } */

    .ag-theme-alpine .ag-paging-panel {
        background-color: #ffffffc4 !important;
        border: none;
    }

    .custom-ag-header:hover {
        background-color: var(--sidebar-color) !important;
    }

    .ag-icon-menu {
        color: white !important;
    }

    .ag-icon-asc,
    .ag-icon-desc {
        color: white !important;
    }

    .ag-header-container {
        background-color: var(--sidebar-color) !important;
    }

    /* AG grid tooltip */
    .ag-tooltip {
        background-color: var(--sidebar-color) !important;
        color: white !important;
        border-radius: 5px !important;
    }

    .ag-paging-row-summary-panel {
        font-size: 7px !important;
        height: 20px !important;
        line-height: 20px !important;
        width: 50% !important;
    }

    .ag-paging-page-summary-panel {
        font-size: 7px !important;
        width: 125% !important;
    }

    .ag-paging-panel {
        height: 2.5rem !important;
        margin-bottom: -10px !important;
    }

    .ag-icon {
        font-size: 10px;
    }



}