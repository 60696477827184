.soa-item-container {
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-rows: auto;
}

.fixed-navbar {
    overflow: hidden;
    background-color: var(--sidebar-color);
    position: fixed;
    top: 0;
    width: 100%;
    color: white;
    padding: 0.25rem;
    height: 4rem;
    z-index: 1;
}

.soa-pdf-container {
    border: 1px solid #ff6c6c;
}

.soa-border-invoice {
    border-left: 5px solid #007bff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.soa-border-payment {
    border-left: 5px solid #00b321;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.soa-border-credit-memo {
    border-left: 5px solid #ff7b00;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}