.invoice-container {
    display: grid;
    grid-template-columns: 15% 50% 25% 10%;
    grid-template-rows: auto;
    gap: 5px;
    color: var(--sidebar-color);
    padding-right: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-10 {
    font-size: 10px;
}

.card-border-cleared {
    border-left: 5px solid #00b321;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.card-border-partially-cleared {
    border-left: 5px solid #ff7b00;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.card-border-open {
    border-left: 5px solid var(--info-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

/* Invoice ddetails card styles */
.ItemsHolder {
    padding: 0.8rem;
}

.ItemCard {
    background-color: rgb(255, 255, 255);
    padding: 14px;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 0.5px;
    /* box-shadow: 4px 4px 6px #d2d2d2; */
    margin-left: 4px;
    margin-right: 4px;

    border: 1px solid lightgrey
}

._1Row {
    display: flex;
    justify-content: space-between;
}

.Product {
    width: 75%;
}

.uom {
    display: flex;
    justify-content: flex-end;
    width: 25%
}

._2Row {
    display: flex;
    padding-top: 14px;
}

.PORef {
    width: 40%;
    /* border: 0.5px solid red; */
}



@media screen and (min-width: 320px) {
    .gross-price {
        display: flex;
        justify-content: flex-end;
        width: 30%;
        /* border: 0.5px solid red; */
    }

    .gross-value {
        display: flex;
        justify-content: flex-end;
        width: 30%;
        /* border: 0.5px solid red; */
    }
}

@media screen and (min-width: 375px) {
    .gross-price {
        display: flex;
        justify-content: flex-end;
        width: 35%;
        /* border: 0.5px solid red; */
    }

    .gross-value {
        display: flex;
        justify-content: flex-end;
        width: 25%;
        /* border: 0.5px solid red; */
    }
}

._3Row {
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
}

._14pxFont {
    font-size: 14px;
}

._12pxfont {
    font-size: 12px;
}

.lite_Grey {
    color: lightslategrey;
}


/* ---- 2 card -------------------------- */

.Row2 {
    padding-top: 14px;
    display: flex;
    justify-content: space-between;
}


.Row3 {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
}

.section1 {
    display: flex;
    justify-content: space-between;
    width: 74%;
    /* border: 0.5px solid red; */
}