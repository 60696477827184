/* .sidebarContainer { */
/* overflow-y: hidden;
    overflow-x: hidden; */
/* height: 100vh; */
/* z-index: 999999 !important; */
/* } */

.custom-tooltip {
    background-color: red !important;
}

.pro-sidebar.collapsed {
    width: 20px;
    min-width: 70px;
    overflow-y: hidden !important;
    /* height: 100%; */
}

.pro-inner-item {
    font-size: 135px !important;
}

.s-closemenu {
    font-family: Source Sans Pro, sans-serif !important;
    font-size: 16px !important;
    padding: 5px 8px !important;
    border-radius: 30px !important;
    /* border: 2px solid #6870FA !important; */
    background-color: inherit;
}

.s-closemenu-span:hover {
    cursor: pointer !important;
}

.s-closemenu:hover {
    cursor: pointer !important;
    background-color: inherit;
}

.pro-sidebar {
    height: 100vh;
    width: 210px;
    min-width: 200px;
    /* position: fixed; */
    overflow-y: hidden !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 0% !important;
}

.css-1l8icbj {
    padding-left: 1% !important;
}

@media screen and (max-width: 768px) {
    .pro-inner-item {
        width: 100% !important;
        display: flex;
        /* flex-direction: column; */
        /* justify-content: center;
        align-items: center; */
    }

    .pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout ul {
        width: 100% !important;
    }

    .pro-sidebar {
        height: 100vh;
        width: 210px;
        min-width: 100px;
        position: fixed;
    }
}