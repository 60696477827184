/*  */
.topbar {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* position: fixed; */
    width: 100%;
    z-index: 10;
    /* margin-bottom: 5rem; */
}

.MuiBadge-badge {
    color: #ffffff !important;
}

.vibration {
    animation: pulse 1.7s ease-in-out infinite;
}

/* Define a CSS animation for the vibration effect */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}

.notification {
    /* width: 90%  !important; */
    left: -10px !important;
    top: 20px !important;
    /* background-color: rgba(229, 229, 229, 0.792) !important; */
    /* min-width: 90% !important; */
    /* max-width: 90% !important; */
}

@keyframes swing {

    0%,
    100% {
        transform: rotate(20deg);
    }

    50% {
        transform: rotate(-20deg);
    }
}

.swing-animation {
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
    /* animation: swing 1s infinite; */
}