/* Mobile M view  */
@media screen and (min-width: 320px) and (max-width: 376px) {
  .p-dropdown-item {
    padding: 10px 20px;
    font-size: 14px;
    color: #333;
    text-align: center;
    height: 2rem !important;
    /* margin-top: -10px; */
  }

  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248, 246, 246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
    font-size: 9px;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
  }

  .containers>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  .containers label {
    font-weight: 600;
  }

  .Note label {
    font-weight: 600;
  }

  .Note span {
    width: 100%;
    text-align: left;
  }

  .BadgeView {
    display: flex;
    justify-content: flex-end;
    width: 130%;
  }

  .p-dropdown-item {
    padding: 10px 20px;
    font-size: 14px;
    color: #333;
    text-align: center;
    height: 2rem !important;
    /* margin-top: -10px; */
  }

  .BadgeView .fontSizeSet {
    font-size: 9px;
  }
}

/* Mobile L view  */
@media screen and (min-width: 376px) and (max-width: 426px) {
  .p-dropdown-item {
    padding: 10px 20px;
    font-size: 14px;
    color: #333;
    text-align: center;
    height: 2rem !important;
    /* margin-top: -10px; */
  }
  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248, 246, 246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
    font-size: 12px;
    margin-bottom: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
  }

  .containers>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  .containers label {
    font-weight: 500;
  }

  .Note label {
    font-weight: 500;
  }

  .Note span {
    width: 100%;
    text-align: left;
  }

  .BadgeView {
    display: flex;
    justify-content: flex-end;
    width: 130%;
  }

  .BadgeView .fontSizeSet {
    font-size: 10px;
  }
}

/* Tablet View  */
@media screen and (min-width: 426px) and (max-width: 768px) {
  .p-dropdown-item {
    padding: 10px 20px;
    font-size: 14px;
    color: #333;
    text-align: center;
    height: 2rem !important;
    /* margin-top: -10px; */
  }
  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248, 246, 246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
    font-size: 11px;
    margin-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    /* gap: 10px; */
  }

  .containers>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    align-items: flex-start;
  }

  .containers label {
    font-weight: 500;
  }

  .Note label {
    font-weight: 500;
  }

  .Note span {
    width: 100%;
    text-align: left;
  }

  .BadgeView {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .BadgeView .fontSizeSet {
    font-size: 8px;
  }
}

/* fullScreen css  */
@media screen and (min-width: 769px) {
  .headerDetails {
    /* border: 1px solid gray;  */
    background-color: rgb(248, 246, 246);
    /* Set the background color to light gray */
    border-radius: 10px;
    /* Set the border radius */
    padding: 4px;
    text-align: center;
    /* Center the content horizontally */
    margin-bottom: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
  }

  .containers {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    gap: 10px;
  }

  .containers div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .containers label {
    color: rgb(168, 159, 159);
    font-weight: 600;
    font-size: 12px;
  }

  .containers span {
    color: black;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  .Note label {
    color: rgb(168, 159, 159);
    font-weight: 500;
  }

  .Note span {
    width: 100%;
  }

  .note-content {
    color: black;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
  }

  .BadgeView {
    display: flex;
    justify-content: flex-end;
    width: 70%;
  }

  .BadgeView .fontSizeSet {
    font-size: 10px;
  }

}

.rotating-icon-anticlockwise {
  margin-left: 0.4rem;
  animation: rotateAntiClockwise 2s linear infinite;
  /* Adjust the duration and timing function as needed */
}

@keyframes rotateAntiClockwise {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
    /* Use a negative degree value for anti-clockwise rotation */
  }
}