.tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
  /* margin-top: 15%; */
}

.MuiChartsSurface-root {
  margin-left: 10px !important;
}

.icon-div {
  background-color: var(--secondary-color);
  border-radius: 50%;
  padding: 0.5rem;
  font-size: small;
}


.tile-title {
  font-size: 18px;
}

.tile-number {
  font-size: 30px;
}

.dashboardCard {
  background-color: rgba(233, 238, 237, 0.264);
  /* background-color: white; */
  transition: background-color 0.3s ease;
}

.dashboardCard:hover {
  background-color: white;
}

@media screen and (min-width: 1024px) {
  .chartCard {
    height: 50vh;
  }

  .Headerdiv {
    margin-top: 20px;
    margin-bottom: -20px;
    margin-left: 20px;
  }
}


/* Tab view  */
@media screen and (min-width: 600px) and (max-width:1023px) {

  .chartCard {
    /* margin-top: 50px; */
    /* margin-left: 50px; */
    /* height: 50vh; */
    margin-bottom: 1rem;
  }

  .Headerdiv {
    margin-top: 20px;
    margin-bottom: -20px;
    margin-left: 20px;
  }

}

/* mobile L view   */
@media screen and (min-width:320px) and (max-width:600px) {
  .trendingandChart {
    /* display: grid;
    grid-template-columns: repeat(1, 1fr); */
    margin-top: -30px;
  }

  .chartCard {
    margin-top: 30px;
    margin-bottom: 15px;
    height: 40vh;
  }

  .Headerdiv {
    margin-top: 20px;
    margin-bottom: -20px;
    margin-left: 20px;
  }

}

/* Add this to your CSS */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.visible {
  visibility: visible;
  animation: slideIn 0.3s ease-in-out;
}

.tile-link {
  position: relative;
  display: inline-block;
  padding: 5px 8px;
  /* background-color: var(--secondary-color); */
  color: var(--secondary-color);
  border-radius: 3px;
  text-decoration: none;
  /* Remove default underline */
}

.tile-link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  border: 1px solid var(--secondary-color);
  border-radius: 3px;
  transition: width 0.3s ease;
  /* Transition the width property */
}

.tile-link:hover::before {
  width: 100%;
  /* Expand the border on hover */
}

@keyframes rotatePieChart {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.custom-pie-chart {
  animation: rotatePieChart 0.5s linear backwards;
}
