.tile-card {
  width: 20px;
  height: 150px;
  padding: 16px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(45, 43, 43);
}

.tile-title {
  margin-top: 0.5rem;
  font-size: 20px;
  /* font-weight: 300; */
}

.tile-number {
  font-size: 25px !important;
}

@media screen and (max-width: 768px) {
  .tile-title {
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .tile-number {
    font-size: 15px !important;
    font-weight: 600 !important;
  }
}