.delivery-container {
    display: grid;
    grid-template-columns: 15% auto;
    grid-template-rows: auto;
    gap: 5px;
    color: var(--sidebar-color);
    padding-right: 16px;
}

.value-color {
    color: #8A8A8A;
}

.deliverycard-border-open {
    border-left: 5px solid var(--sidebar-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.deliverycard-border-released {
    border-left: 5px solid #00b321;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.deliverycard-border-notreleased {
    border-left: 5px solid var(--info-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}