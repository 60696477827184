.background-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 55%;
    height: 100%;
    background-color: rgba(255, 165, 0, 0.5);
    z-index: 2;
}

#BG_video {
    object-fit: cover;
    width: 55%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 1; */
}

.animated-text {
    position: absolute;
    left: 0;
    top: 43%;
    width: 50%;
    z-index: 4;
    padding: 10px;
    text-align: center;
    color: white;
    filter: drop-shadow(3px 0px 3px rgb(70, 69, 69));
}

.fade-in-out {
    animation: slideUpDown 5s ease infinite;
}

.social-icons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.icon img {
    width: 20px;
    height: 20px;
}

/* BusinessQuotes.css */

.business-quotes {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quote {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.quote.active {
    opacity: 1;
    transform: translateY(0);
}

/* Animation delay */
.quote {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.youtube-link {
    position: relative; /* Ensure the parent container has a position value */
}

.youtube-link::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2px; /* Adjust the width as needed */
    background-color: var(--primary-color); /* Adjust the color as needed */
}
