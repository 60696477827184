.swiper {
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}


.swiper-button-next {
  background-image: url("./next.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  color: transparent;
  background-color: white !important;
  border-radius: 10px;
}

.swiper-button-prev {
  background-image: url("./previous.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  color: transparent;
  background-color: white !important;
  border-radius: 10px;
}

.slide-title {
  font-weight: 500;
  font-size: 14px;
}

.discount-badge {
  font-size: 12px;
  font-weight: bold;
  color: var(--success-color);
}


@media screen and (max-width: 1440px) {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    /* height: 200px; */
  }
}

@media screen and (max-width: 768px) {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    /* height: 210px; */
  }

  .swiper-slide img {
    width: 280px;
    /* height: 120px; */
    object-fit: contain;
  }
}