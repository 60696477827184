.priceAmountDivContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 3 columns with equal width */
  gap: 10px;
  /* Adjust this value to add spacing between divs */
}

.custom-place-order-btn:focus {
  outline: none;
}

.radio-selected {
  border-radius: 5px;
  /* padding: 5px; */
  background-color: whitesmoke !important;
}


.marquee-container {
  width: 41%;
  height: 3vh;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  background-color: rgb(239, 237, 237);
}

.marquee {
  font-size: 13px;
  color: black;
  position: absolute;
  animation: marquee 10s linear infinite;
}

.marquee.paused {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.placeorder-button {
  font-size: 15px !important;
}


.place-order-amount {
  color: var(--black-color);
  font-weight: 700 !important;
  font-size: 18px !important;
}

.po-reference {
  box-shadow: none !important;
}

.notes:focus {
  border: 1px solid var(--primary-color) !important;
}

.select-address {
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 5px;
  outline: none;
  width: 16rem;
}

.select-address option {
  padding: 8px 12px !important;
  border: 1px solid lightgray !important;
}

.place-oreder-loading {
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 94%;
  height: 90vh;
  background-color: rgba(251, 245, 245, 0.667);
  z-index: 100;
  /* Set a higher z-index value */
}

.ProudctTrendingChange-loading {
  position: fixed;
  /* top: 0;
  left: 0; */
  width: 94%;
  height: 85vh;
  background-color: rgba(251, 245, 245, 0.667);
  z-index: 100;
  /* Set a higher z-index value */
}

@media (min-width: 769px) {
  .custom-grid {
    display: grid !important;
    grid-template-columns: 3fr 1fr !important;
    justify-content: flex-start !important;
  }
}