.input-counter {
    width: 10px !important;
}

.product-card {
    /* cursor: pointer; */
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
    /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.product-card:hover {
    /* cursor: pointer; */
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.product-card img {
    cursor: pointer;
}

.p-tabview-nav {
    background-color: #f9f9f936 !important;

}

.p-tabview-ink-bar {
    background-color: #fa9933 !important;
}

.p-tabview-panels {
    display: none !important;
}


.p-dataview .p-dataview-header {
    background: #f8f9fa;
    color: #495057;
    border: 0px solid #2b5076 !important;
    border-width: 1px 0 1px 0;
    padding: 10px !important;
    font-weight: 600;
}

.css-1d574l3-MuiTabs-indicator {
    background-color: var(--sidebar-color) !important;
}

.overlay {
    position: fixed;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 211, 211, 0.571);
    z-index: 999;
}

.product-description-container {
    display: flex;
    align-items: flex-end;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3rem;
}

.title {
    font-size: 20px;
    font-weight: 600;
}

@media screen and (max-width: 1350px) and (min-width: 990px) {
    .add-to-cart {
        display: none;
    }
}

/* .zoom-image {
    transition: transform 0.2s ease;
    position: relative; 
    z-index: 1; 
    transform-origin: 100% 100% 100%;
}

.zoom-image:hover {
    transform: scale(2); 
    z-index: 2; 
} */

.circle-border {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* This creates the circular shape */
    background-color: var(--sidebar-color) !important;
    /* This sets the filled gray color */
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* Add this to keep the position context for z-index */
}

/* Optionally, you can remove default button styles (adjust as needed) */
.circle-border button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

/* Style for the fixed-icon-button-container */
.fixed-icon-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
}

.discount-badge {
    font-size: 12px;
    font-weight: bold;
    color: var(--success-color);
}

.saved-amount {
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: var(--success-color);
    padding: 3px 5px;
    /* border-radius: 8px; */
}

.bg-addedToCart {
    /* background-color: #dfef2ea5; */
    background-color: rgba(234, 224, 224, 0.229);
    border: 2px solid red;
}

.loading-div {
    position: relative;
}

.loading-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(214, 207, 207, 0.37);
    z-index: 1;
    opacity: 0;
    transition: opacity 1s ease;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading-div.loading-effect::before {
    opacity: 1;
    pointer-events: auto;
}

.loading-circle {
    width: 20px;
    height: 20px;
    border: 2px solid var(--sidebar-color);
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.centered-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.glowing {
    animation: glowingAnimation 1s infinite alternate;
    background-color: var(--success-color);
    color: white;
    border-radius: 50%;
    padding: 2px;

}

@keyframes glowingAnimation {
    0% {
        box-shadow: 0 0 0px var(--success-color);
    }

    100% {
        box-shadow: 0 0 20px var(--success-color);
    }
}

@media (min-width: 1024px) and (max-width: 1224px) {
    .product-card {
        width: 20% !important;
    }
}


.panel-container {
    position: relative;
}

.search-container {
    position: absolute;
    top: 1%;
    left: 55%;
    transform: translateX(-50%);
    z-index: 999;
    /* Set a high z-index value */
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80.5vh;
}

.empty-product-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
}

.CatalogCardButton {
    padding: 0.6rem !important;
}

.CatalogCardGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 7px;
}

.ribbon {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    --r: .4em;
    --c: rgb(21, 201, 120);

    position: absolute;
    top: 28px;
    left: calc(-1*var(--r));
    line-height: 1.8;
    padding: 0 .5em calc(2*var(--r));
    border-radius: var(--r) 0 0 var(--r);
    background:
        radial-gradient(100% 50% at left, var(--c) 98%, #0000 101%) 100% 0/.5lh calc(100% - 2*var(--r)),
        radial-gradient(100% 50% at right, #0005 98%, #0000 101%) 0 100%/var(--r) calc(2*var(--r)),
        conic-gradient(from 90deg at var(--r) calc(100% - 2*var(--r)), #0000 25%, var(--c) 0) 0 0/calc(101% - .5lh) 100%;
    background-repeat: no-repeat;
}

.ribbons {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    --r: .4em;
    --c: rgb(77, 214, 232); 
    position: absolute;
    top: 28px;
    left: calc(-1*var(--r));
    line-height: 1.8;
    padding: 0 .5em calc(2*var(--r));
    border-radius: var(--r) 0 0 var(--r);
    background:
        radial-gradient(100% 50% at left, var(--c) 98%, #0000 101%) 100% 0/.5lh calc(100% - 2*var(--r)),
        radial-gradient(100% 50% at right, #0005 98%, #0000 101%) 0 100%/var(--r) calc(2*var(--r)),
        conic-gradient(from 90deg at var(--r) calc(100% - 2*var(--r)), #0000 25%, var(--c) 0) 0 0/calc(101% - .5lh) 100%;
    background-repeat: no-repeat;
}



.scroll-top-right {
    position: fixed;
    bottom: 2rem;
    right: 4rem;
}

#popup-overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.814); /* Use rgba for transparency effect */
    backdrop-filter: blur(8px);
    width: 100%;
    height: 100vh;
    top: 2.5rem; /* Adjusted the top property */
    left: 0;
    /* z-index: 20; */
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
}
