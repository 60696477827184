/* Main content styles */
/* Footer styles */
.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ccc;
  z-index: 10;
}

.footer-icons {
  color: #D2D2D2;
  /* display: flex; */
  /* justify-content: space-around; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: center;
  padding: 10px;
}

.selected {
  color: #FFA216;
}

.selected svg {
  fill: #FFA216;
}