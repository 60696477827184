.ribbonCard {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
  .ribbonCard {
    --r: .4em; /* control the ribbon shape (the radius) */
    --c: rgb(21, 201, 120);
  
    position: absolute;
    top: 25px;
    z-index: 10;
    left: calc(-1*var(--r));
    line-height: 1.8; 
    padding: 0 .5em calc(2*var(--r));
    border-radius: var(--r) 0 0 var(--r);
    background: 
      radial-gradient(100% 50% at left,var(--c) 98%,#0000 101%)
       100% 0/.5lh calc(100% - 2*var(--r)),
      radial-gradient(100% 50% at right,#0005 98%,#0000 101%) 
       0 100%/var(--r) calc(2*var(--r)),
      conic-gradient(from 90deg at var(--r) calc(100% - 2*var(--r)),#0000 25%,var(--c) 0)
       0 0/calc(101% - .5lh) 100%;
    background-repeat: no-repeat;   
  }

  