.datanotfound-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;
}

.datanotfound-image {
  width: 250px;
  /* Adjust the size as needed */
  margin-bottom: 20px;
}