.credit-container {
    display: grid;
    grid-template-columns: 15% auto 30%;
    grid-template-rows: auto;
    gap: 5px;
    color: var(--sidebar-color);
    padding-right: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-10 {
    font-size: 10px;
}