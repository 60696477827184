.login-card {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    /* position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%); */
    /* width: 30rem; */
    border-radius: 10px;
    background-color: white;
}

.login-button {
    padding: 12.5px 30px;
    border: 0;
    border-radius: 100px;
    background-color: var(--primary-color);
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.login-button:hover {
    background-color: #e09729;
}

.login-button:active {
    background-color: #e09729;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
}

.form-container {
    width: 350px;
    height: 280px;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
}

.login-title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 10px 0 10px 0;
    font-size: 23px;
    font-weight: 400;
}

.welcome-title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    /* margin: 10px 0 30px 0; */
    font-size: 18px;
    font-weight: 600;
    color: white;
    background-color: var(--primary-color);
    padding: 10px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.sub-title {
    margin: 0;
    margin-bottom: 5px;
    font-size: 9px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 15px;
}

.login-input {
    margin-top: 1rem;
    border-radius: 100px;
    /* border: 1px solid #c0c0c0; */
    outline: 0 !important;
    box-sizing: border-box;
    padding: 12px 15px;
}

.login-input-email {
    margin-top: 1rem;
    /* border-radius: 100px; */
    border-bottom: 1px solid gray;
    outline: 0 !important;
    box-sizing: border-box;
    padding: 12px 15px;
}

.login-input-email:focus {
    border-color: var(--primary-color) !important;
}

.otp-input {
    border-radius: 100px;
    border: 1px solid #c0c0c0;
    outline: 0 !important;
    box-sizing: border-box;
    padding: 12px 15px;
}

.otp-input:focus {
    border-color: var(--primary-color);
}

.google-login-button {
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    gap: 5px;
    border: 2px solid var(--primary-color);
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 100px;
}

.google-login-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.google-icon {
    font-size: 18px;
    margin-bottom: 1px;
}

/* Footer styles */
.footer {
    color: #232121;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    position: absolute;
    bottom: 13%;
    /* width: 100%; */
}

.copyright {
    font-weight: 400;
}

@media screen and (max-width: 767px) {

  .hide-on-small-screens {
      display: none;

  }
 
  .login-card {
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* width: 30rem; */
      border-radius: 10px;
      background-color: white;
      
  }
  
  .login-button {
      padding: 12.5px 30px;
      border: 0;
      border-radius: 100px;
      background-color: var(--primary-color);
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
  }
  
  .login-button:hover {
      background-color: #e09729;
  }
  
  .login-button:active {
      background-color: #e09729;
      transition: all 0.25s;
      -webkit-transition: all 0.25s;
      box-shadow: none;
      transform: scale(0.98);
  }
  
  .form-container {
      width: 310px;
      height: 270px;
      background-color: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 20px 30px;
  }
  
  .login-title {
      text-align: center;
      font-family: 'Poppins', sans-serif;
      margin: 10px 0 10px 0;
      font-size: 19px;
      font-weight: 390;
  }
  
  .welcome-title {
      text-align: center;
      font-family: 'Poppins', sans-serif;
      /* margin: 10px 0 30px 0; */
      font-size: 18px;
      font-weight: 600;
      color: white;
      background-color: var(--primary-color);
      padding: 10px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
  }
  
  .sub-title {
      margin: 0;
      margin-bottom: 5px;
      font-size: 9px;
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
          "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  
  .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 15px;
  }
  
  .login-input {
      margin-top: 1.5rem;
      border-radius: 90px;
      border: 1px solid #c0c0c0;
      outline: 0 !important;
      box-sizing: border-box;
      padding: 5px 8px;
  }
  
  .login-input:focus {
      border-color: var(--primary-color);
  }
  
  .otp-input {
      border-radius: 100px;
      border: 1px solid #c0c0c0;
      outline: 0 !important;
      box-sizing: border-box;
      padding: 12px 15px;
  }
  
  .otp-input:focus {
      border-color: var(--primary-color);
  }
  
  .google-login-button {
      border-radius: 20px;
      box-sizing: border-box;
      padding: 10px 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
      font-size: 15px;
      gap: 5px;
      border: 2px solid var(--primary-color);
      transition: background-color 0.3s ease, color 0.3s ease;
      border-radius: 100px;
  }
  
  .google-login-button:hover {
      background-color: var(--primary-color);
      color: white;
  }
  
  .google-icon {
      font-size: 18px;
      margin-bottom: 1px;
  }

  .footer {
      color: #232121;
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      position: absolute;
      bottom: 2%;
     
  }
  .copyright {
      font-weight: 400;
  }

 
}

  
