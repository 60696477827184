/* YourComponent.css */
.background-svg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./pattern_jigsaw.png') ;
    background-size:contain; 
    z-index: -1; /* Place the SVG behind other content */
  }
  
  .svg-container {
    position: relative;
  }
  