.paymenthistory-container {
    display: grid;
    grid-template-columns: 15% auto 35%;
    grid-template-rows: auto;
    gap: 5px;
    color: var(--sidebar-color);
    padding-right: 16px;
}

.card-border-in-process {
    border-left: 5px solid var(--sidebar-color);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.card-border-completed {
    border-left: 5px solid #00b321;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}